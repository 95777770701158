<template>
	<div>
		<el-card>
			<el-row :gutter="20" class="left">
				<el-col :span="24">
					<span style="color: #666">退款管理</span>
				</el-col>
			</el-row>
			<el-divider></el-divider>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true" :model="queryForm" ref="reference">
						<el-form-item label="服务名称" prop="serviceName">
							<el-input v-model="queryForm.serviceName" placeholder="请输入服务名称" clearable></el-input>
						</el-form-item>
						<el-form-item label="订单号" prop="orderNumber">
							<el-input v-model="queryForm.orderNumber" placeholder="请输入系统订单号" clearable></el-input>
						</el-form-item>
						<!-- <el-form-item label="状态" prop="options">
							<el-select v-model="queryForm.status" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-form-item> -->
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>

			<el-table :data="orderListTable" style="width: 100%" height="520" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column fixed prop="order_id" label="ID" width="100"></el-table-column>
				<el-table-column prop="order_sn" label="订单号" width="180"></el-table-column>
				<el-table-column label="服务项目信息" width="280">
					<template slot-scope="scope">
						<div class="service">
							<div class="service_img"><img :src="scope.row.service_info.service_logo" alt=""></div>
							<div class="service_info">
								<div class="info">
									<div>{{scope.row.service_info.service_name}}</div>
									<div v-show="scope.row.order_status==4" class="refundCount">已退</div>
								</div>
								<div class="price_count">
									<div class="price">￥{{scope.row.service_info.service_price}}</div>
									<div class="count">x{{scope.row.service_info.service_number}}</div>
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="consignee" label="下单人" width="120"></el-table-column>
				<el-table-column prop="jishi_name" label="技师" width="120"></el-table-column>
				<el-table-column label="实付金额(含车费)" width="180">
					<template slot-scope="scope">
						<div>￥{{scope.row.pay_fee}}</div>
					</template>
				</el-table-column>
				<el-table-column label="退款金额" width="120">
					<template slot-scope="scope">
						<div>￥{{scope.row.pay_fee}}</div>
					</template>
				</el-table-column>
        <el-table-column label="支付方式" width="120">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.pay_id===1">余额支付</el-tag>
            <el-tag type="success" v-if="scope.row.pay_id===2">微信</el-tag>
            <el-tag type="primary" v-if="scope.row.pay_id===3">支付宝</el-tag>
          </template>
        </el-table-column>
				<el-table-column prop="fk_bh" label="付款订单号" width="180"> </el-table-column>
				<el-table-column prop="pay_reverse_id" label="退款订单号" width="180"> </el-table-column>
<!--				<el-table-column prop="party_order_id" label="微信支付订单号" width="220"> </el-table-column>-->
				<el-table-column label="状态" width="100">
					<template slot-scope="scope">
						<div>{{scope.row.status===1?'同意退款':scope.row.status===0?'拒绝退款':'退款申请中'}}</div>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="100">
					<template slot-scope="scope">
						<div class="btns">
							<el-button size="mini" type="primary" plain @click="viewDetail(scope.row)">查看</el-button>
<!--							<el-button v-show="scope.row.status>1" size="mini" type="danger" plain @click="refuse">拒绝退款</el-button>-->
<!--							<el-button v-show="scope.row.status>1" size="mini" type="success" plain @click="refund(scope.row.pay)">立即退款</el-button>-->
						</div>
					</template>
				</el-table-column>
			</el-table>
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<el-dialog @close="closeRefund" title="立即退款" center :visible.sync="refundVisible" width="35%">
				<el-alert title="注:退款金额不得大于实付金额" type="warning" :closable="false" show-icon></el-alert>
				<el-tag type="danger" class="top">当前最大可退金额:{{pay}}</el-tag>
				<el-form label-width="130px" class="top">
					<el-form-item label="请输入退款金额:">
						<el-input type="number" v-model="refundPrice" placeholder="请输入退款金额"></el-input>
					</el-form-item>
				</el-form>

				<div class="top center">
					<el-button type="default" @click="closeRefund">取消</el-button>
					<el-button type="primary" @click="subRefund">确定</el-button>
				</div>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				total: 0,
				queryForm: {
					serviceName: "",
					orderNumber: "",
				},
				options: [{
						value: "0",
						label: "全部订单"
					},
					{
						value: "3",
						label: "退款申请中"
					},
					{
						value: "1",
						label: "同意退款"
					},
					{
						value: "2",
						label: "拒绝退款"
					},
				],
				orderListTable: [],
				refundVisible: false,
				refundPrice: null,
				pay: 0
			};
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.getOrderList();
			}
		},
		methods: {
			getOrderList() {
				var url = 'order/refund_list';
				let params = {
					service_name: this.queryForm.serviceName,
					order_sn: this.queryForm.orderNumber,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.orderListTable = res.list;
						console.log(res.list);
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1
				this.queryInfo.pagesize = newSize
				this.getOrderList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage
				this.getOrderList();
			},
			//搜索
			search() {
				this.getOrderList();
			},
			//重置
			reset() {
				this.$refs["reference"].resetFields();
				this.getOrderList();
			},
			//查看
			viewDetail(row) {
				this.$router.push({path:'/order_refund/refund_detail',query: {order_id: row.order_id}})
			},
			//拒绝退款
			refuse() {
				this.$confirm('你确认要拒绝退款吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$message.success('退款成功!');
				}).catch(() => {
					this.$message.info('已取消退款');
				});
			},
			//立即退款
			refund(pay) {
				this.pay = pay
				this.refundVisible = true
			},
			//取消退款
			closeRefund() {
				this.refundVisible = false
				this.refundPrice = null
				this.pay = 0
			},
			//确定退款
			subRefund() {
				if(Number(this.refundPrice) > this.pay) {
					this.$message.error('请输入正确金额!')
					this.refundPrice = null
					return
				}
				this.$message.success('退款成功')
				this.refundPrice = null
				this.refundVisible = false
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import "./Order_refund.scss";
</style>